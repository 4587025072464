<template>
  <div class="account_block">
    <div class="account_title_block">
      <div class="account_title_container">
        <div class="account_title_label">FAQ</div>
        <div class="account_title_text">
          <img :src="category.icon && getIconPath(category.icon)" alt="">
          {{category.translations.find(({locale}) => locale === currentLocale).name}}
        </div>
      </div>
    </div>
    <div class="account_answers_block">
      <div class="account_answers_container">
        <div class="account_answers_group" v-for="key in Object.keys(data)">
          <div class="account_answers_group_item" v-for="item in data[key]">
            <div @click="toggle(item.id)" class="account_answers_group_item_arrow_up" v-show="activeItem == item.id"/>
            <div @click="toggle(item.id)" class="account_answers_group_item_arrow_down" v-show="activeItem != item.id"/>
            <div class="account_answers_group_item_title" @click="toggle(item.id)">
              {{item.translations.find(({locale}) => locale === currentLocale).title}}
            </div>
            <div
              v-show="activeItem == item.id"
              class="account_answers_group_item_text"
              v-html="item.translations.find(({locale}) => locale === currentLocale).text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Account",
    data() {
      return {
        data: {},
        activeItem: 0,
        category: {},
        currentLocale : this.$i18n.locale,
      }
    },
    methods: {
      toggle(id) {
        this.activeItem = this.activeItem != id ? id : 0;
      },
      getIconPath(path) {
        return API_ROOT + path;
      }
    },
    mounted() {
      const categoryId = this.$route.params.id;

      this.$http.get(`${API_ROOT}/api/fag/categories/${categoryId}`)
        .then(({data}) => {
          this.category = data;
        })
        .catch(err => {
          console.error(err);
        });

      this.$http.get(`${API_ROOT}/api/fag`, {
        params: { category_id: categoryId }
      })
        .then(({data}) => {
          this.data = data;
          this.activeItem = data.instructor.length > 0 ? data.instructor[0].id : data.student[0].id;
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
</script>

<style scoped>

</style>
